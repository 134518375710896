.ecommerce_relatedItems {
    @include pv-50;
    .slick-next {
        right: -50px;
    }

    .slick-prev {
        left: -50px; 
    }

    .section-title{
        @include pb(2);
    }

    .product-preview  {
        padding: 0 3%;
 

        @media (min-width: $tablet-P) {
           padding: 0 8%; 
        }

        .product-content {
            a:hover {
                text-decoration: none;
            }
        }

        .product-preview__gallery {
            text-align: center;
        }

        img {
            display: inline-block;
            position: relative;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .ec_btn_primary { 
        font-size: 14px;
    }
    .btn_addToCart.button-primary {
        min-height: 45px;
        height: auto;
    }

    .slick-track{
        display: flex !important;
    }

    .slick-slide{
        height: inherit !important;

        .product-preview{
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            height: 100%;
        }

        .product-links{
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            height: 100%;
        }

        .product-link__buy{
            flex: 1;
        }
    }

    .slick-slide>div{
        height: 100%;
    }

}