@import "../smeg/theming/variables";
@import "../shared/global/breakpoints";
@import "../shared/global/mixin";

#myAccount{
    label + p.error {
        line-height: 1.2rem;
    }
}


