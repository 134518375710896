#reactPlaceholder_paymentMethods {
    flex-basis: 100%; 
    height: fit-content;
    margin-top: 5vw;

    display: flex;;
    align-items: center;

    @media (min-width: $mobile) {
        margin-top: 1.5vw;
    }
 
    .titolo {
        color: $color-secondary;
        text-transform: uppercase;
        font-size: .8rem;
        flex: 1;
    }

    .certifications {
        .teh-certificate {
            img {
                width: 64px;
                height: 64px;
                padding: 10px;
            }
        }
    }
}

.paymentMethodsIcons {

    display: flex;
    align-items: center; 
    justify-content: center;
    flex-grow: 1;
    max-width: 100%;
    flex-wrap: wrap;

    .checkout-column-main & {
        display: none;

        @media (min-width: $tablet-L) {
            display: flex;  
        }
    }

    .checkout-column-aside & {
        padding: 5px 10px;
    }

    .footer & {
        padding-left: 0;
        justify-content: flex-end;
        
        margin-bottom: 0; 
        flex: 1; 
    }

    li { 
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px; 
        height: 35px;

 
        .icon {
            display: inline-block;
            width: 100%;
            height: 100%;
            display: inline-block;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &.paymentType-paypal {
            width: 80px;
            .icon{
                background-image: url('#{$images}/icons/paymentMethods/paypal.svg');
                height: 38%;
            .footer & {
                background-image: url('#{$images}/icons/paymentMethods/paypal-white.svg');
            }
        }

        }

        &.paymentType-payU {
            width: 80px;
            .icon{
                background-image: url('#{$images}/icons/paymentMethods/payU.svg');
                height: 50%;
                background-position: 0;
                .footer & {
                    background-image: url('#{$images}/icons/paymentMethods/payU-white.svg');
                }
            }
        }
         
        &.paymentType-visa .icon{
            background-image: url('#{$images}/icons/paymentMethods/visa.svg');
            width: 70%;

            .footer & {
                background-image: url('#{$images}/icons/paymentMethods/visa-white.svg');
            }
        }

        &.paymentType-mastercard .icon{
            background-image: url('#{$images}/icons/paymentMethods/mastercard.svg');
            height: 90%;

            .footer & {
                background-image: url('#{$images}/icons/paymentMethods/mastercard-white.svg');
            }
        }
        &.paymentType-cartebancaire .icon{
            background-image: url('#{$images}/icons/paymentMethods/cartebancaire.png');
            height: 90%;

            .footer & {
                background-image: url('#{$images}/icons/paymentMethods/cartebancaire-white.png');
            }
        }
        &.paymentType-amex .icon{
            background-image: url('#{$images}/icons/paymentMethods/amex.png');
            height: 90%;

            .footer & {
                background-image: url('#{$images}/icons/paymentMethods/amex-white.png');
            }
        }
        &.paymentType-klarna .icon{
            background-image: url('#{$images}/icons/paymentMethods/klarna.svg');
            height: 90%;

            .footer & {
                background-image: url('#{$images}/icons/paymentMethods/klarna-white.svg');
            }
        }
        &.paymentType-sepa .icon{
            background-image: url('#{$images}/icons/paymentMethods/sepa.svg');
            height: 90%;

            .footer & {
                background-image: url('#{$images}/icons/paymentMethods/sepa-white.svg');
            }
        }
        &.paymentType-bancontact .icon{
            background-image: url('#{$images}/icons/paymentMethods/bancontact.svg');
            height: 90%;

            .footer & {
                background-image: url('#{$images}/icons/paymentMethods/bancontact-white.svg');
            }
        }
        &.paymentType-applepay .icon{
            background-image: url('#{$images}/icons/paymentMethods/applepay.svg');
            height: 90%;

            .footer & {
                background-image: url('#{$images}/icons/paymentMethods/applepay-white.svg');
            }
        }
        &.paymentType-ideal .icon{
            background-image: url('#{$images}/icons/paymentMethods/ideal.svg');
            height: 90%;

            .footer & {
                background-image: url('#{$images}/icons/paymentMethods/ideal-white.png');
            }
        }
        &.paymentType-younited .icon{
                    background-image: url('#{$images}/icons/paymentMethods/younited.svg');
                    height: 90%;

                    .footer & {
                        background-image: url('#{$images}/icons/paymentMethods/younited-white.svg');
                    }
                }

        .testo {
            @include visuallyHidden;
        } 

    }

} 

.footerPartnership.payments {
    .icons {
        .paymentMethodsIcons {
            li {
                width: 60px !important;

                &.paymentType-paypal{
                    width: 80px !important;
                }
                &.paymentType-payU {
                    width: 80px !important;
                }

                .icon {
                    width: 100% !important; 
                    height: 30px !important;
                    margin-right: 8px;
                }
            }
            
        }
    }
}
 