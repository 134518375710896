
/**
 * Style for the component 
 * customerCareInfo.tsx
 *
 */

.customerCareInfo {
    line-height: 1.5;     
    font-size: 0.9rem; 

    h3 {
        text-align: center;
    }

    &.layout-row {
        .wrapper {
            @include rowgrid;
            justify-content: center;
        }

        [class*='customerCareInfo-']
        { 
            @include col-lg-4 ();
        } 
    }   
}

[class*='customerCareInfo-box-'] { 
    display: flex; 
    line-height: 1.5;
    
    .icon {
        display: inline-block; 
        font-size: 1.875rem;
        padding-right: 1.875rem; 
    }   

    .testo {
        a {
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    .customerCareInfo & {
        margin-bottom: 40px;
    }
}


.customerCareInfo-box-faq { 
    .icon {
        background-image: url('#{$images}/icons/ecommerce/faq_white.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-position: top;
        width: 30px;
        margin-right: 1.875rem;
    }
}

.customerCareInfo-box-tel { 
    .icon {
        background-image: url('#{$images}/icons/ecommerce/phone-icon.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 30px;
        margin-right: 1.875rem;
    }
}

.customerCareInfo-box-mail { 
    .icon {
        background-image: url('#{$images}/icons/ecommerce/mail_form_white.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-position: top;
        width: 30px;
        margin-right: 1.875rem;
    }
}

#cart .customerCareInfo-box-tel span.icon {
    background-position: top !important;
}